// [
//   "Sex", -1, 0, 1
//   "AnStudiu", 0-7
//   "MediuUrban", 0, 1
//   "NrLuni", 1-10
//   "NumarNote",
//   "MediaNotelor",
//   "NumarAbsente",
//   "NumarAbsenteMotivate",
// ];

const inputValues = [
  [1, 0, 1, 8, 64, 9.35, 9, 9],
  [1, 3, 1, 6, 50, 5.4, 115, 89],
];

export default inputValues;

export const getRandomInput = () => {
  const values = inputValues[Math.floor(Math.random() * inputValues.length)];
  return convertArrayToInput(values);
};

export const convertArrayToInput = (values: number[]) => {
  return {
    sex: values[0],
    months: values[3],
    year: values[1],
    is_urban: values[2],
    grade_count: values[4],
    grade_average: values[5],
    absences_count: values[6],
    absences_count_justified: values[7],
  };
};

export const convertInputToArray = (input: any) => {
  return [
    input.sex,
    input.year,
    input.is_urban,
    input.months,
    input.grade_count,
    input.grade_average,
    input.absences_count,
    input.absences_count_justified,
  ];
};
