import React, { useState } from "react";
import { Layout, Space } from "antd";
import PredictionForm from "./Form";
import Predictions from "./Predictions";
import { Prediction } from "./types";

const { Header, Footer, Sider, Content } = Layout;

const App: React.FC = () => {
  const [prediction, setPredictions] = useState<Prediction>(null);

  return (
    <Space direction="vertical" style={{ width: "100%", height: "100vh" }}>
      <Layout style={{ height: "100vh" }}>
        <Header className="header">PREDICȚIE A PROGRESULUI ȘCOLAR</Header>
        <Layout hasSider>
          <Content
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PredictionForm onPredict={setPredictions} />
          </Content>
          <Sider width={"50%"} style={{ backgroundColor: "#2F4F4F" }}>
            <Predictions prediction={prediction} />
          </Sider>
        </Layout>
        <Footer>&copy; Daniel Onisoru, 2023</Footer>
      </Layout>
    </Space>
  );
};

export default App;
