import React, { useEffect } from "react";
import "./Predictions.css";
import { Prediction } from "./types";

const Predictions: React.FC<{
  prediction: Prediction;
}> = (props) => {
  const [values, setValues] = React.useState<Prediction>({
    abandon: 0,
    medie: 0,
    absente: 0,
  });

  useEffect(() => {
    if (props.prediction == null || !props.prediction?.abandon) {
      setValues({
        abandon: 0,
        medie: 0,
        absente: 0,
      });

      return;
    }

    const prediction = {
      abandon: props.prediction?.abandon ?? 0,
      medie: props.prediction?.medie ?? 0,
      absente: props.prediction?.absente ?? 0,
    };

    let steps = 40;

    const incrementValues = () => {
      if (--steps < 0) {
        clearTimeout(animationTimeout);
        setValues(prediction);
        return;
      }

      setValues((prevValues) => ({
        abandon:
          Math.round(
            ((prevValues?.abandon ?? 0) + prediction.abandon / 40) * 100
          ) / 100,
        medie:
          Math.round(((prevValues?.medie ?? 0) + prediction.medie / 40) * 100) /
          100,
        absente: Math.round(
          (prevValues?.absente ?? 0) + prediction.absente / 40
        ),
      }));
    };

    const animationTimeout = setInterval(incrementValues, 10);

    return () => clearTimeout(animationTimeout);
  }, [props.prediction]);

  const decorateValue = (value: number, percentage: boolean = false) => {
    if (!value) return "N/A";
    return percentage ? `${value}%` : value;
  };

  return (
    <div id="predictions">
      <div className="row">
        <div className="col">
          <div className="prediction">
            <span>Probabilitate de abandon</span>
            <h1>{decorateValue(values?.abandon ?? 0, true)}</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="prediction">
            <span>Media finala</span>
            <h2>{decorateValue(values?.medie ?? 0)}</h2>
          </div>
        </div>
        <div className="col">
          <div className="prediction">
            <span>Număr absențe</span>
            <h2>{decorateValue(values?.absente ?? 0)}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Predictions;
