import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./index.css";
import { Button, Form, InputNumber, Radio, Space } from "antd";
import { Prediction } from "./types";
import { Tensor, loadGraphModel, tensor } from "@tensorflow/tfjs";
import { convertInputToArray, getRandomInput } from "./helpers";

const sexOptions = [
  { label: "Masculin", value: 1 },
  { label: "Feminin", value: -1 },
  { label: "Nespecificat", value: 0 },
];

const yearOptions = [
  { label: "V", value: 0 },
  { label: "VI", value: 1 },
  { label: "VII", value: 2 },
  { label: "VIII", value: 3 },
  { label: "IX", value: 4 },
  { label: "X", value: 5 },
  { label: "XI", value: 6 },
  { label: "XII", value: 7 },
];

const urbanOptions = [
  { label: "Urban", value: 1 },
  { label: "Rural", value: 0 },
];

const PredictionForm: React.FC<{
  onPredict: Dispatch<SetStateAction<Prediction>>;
}> = (props) => {
  const [form] = Form.useForm();
  const [tfModels, setTfModels] = useState<any>(null);

  useEffect(() => {
    const loadModels = async () => {
      const model = await loadGraphModel("/models/abandon_web/model.json");
      setTfModels({ abandon: model });
    };
    loadModels();
  }, []);

  const onFinish = async (values: any) => {
    props.onPredict({
      abandon: 0,
      medie: 0,
      absente: 0,
    });

    const prediction = await (
      tfModels.abandon.predict(tensor([convertInputToArray(values)])) as Tensor
    ).data();
    props.onPredict({
      abandon: Math.round(prediction[0] * 10000) / 100,
      medie: 9.54,
      absente: 256,
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    props.onPredict({
      abandon: 0,
      medie: 0,
      absente: 0,
    });
    console.log("Failed:", errorInfo);
  };

  const randomizeValues = () => {
    form.setFieldsValue(getRandomInput());
  };

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 20 }}
      wrapperCol={{ span: 16 }}
      style={{ width: 550 }}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Sex"
        name="sex"
        rules={[{ required: true, message: "Va rugam alegeți o opțiune" }]}
      >
        <Radio.Group
          options={sexOptions}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>

      <Form.Item
        label="Anul de studiu"
        name="year"
        rules={[{ required: true, message: "Va rugam alegeți o opțiune" }]}
      >
        <Radio.Group
          options={yearOptions}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>

      <Form.Item
        label="În ce tip de mediu se află localizată școala?"
        name="is_urban"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Va rugam alegeți o opțiune" }]}
      >
        <Radio.Group
          options={urbanOptions}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
      <Form.Item
        label="Numărul de luni de la începerea anului școlar (1-10)"
        name="months"
        rules={[
          {
            message: "Va rugam să introduceți o valoare validă",
            required: true,
            type: "number",
            min: 1,
            max: 10,
          },
        ]}
      >
        <InputNumber min={1} max={10} />
      </Form.Item>
      <h4>Note</h4>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Numărul de note"
          name="grade_count"
          rules={[
            {
              required: true,
              type: "number",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(40% - 8px)",
          }}
        >
          <InputNumber
            min={0}
            max={999}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="grade_average"
          label="Media notelor"
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(60% - 8px)",
            margin: "0 8px",
          }}
        >
          <InputNumber decimalSeparator="." min={0} max={10} />
        </Form.Item>
      </Form.Item>

      <h4 style={{ marginTop: 0 }}>Absențe</h4>
      <Form.Item>
        <Form.Item
          label="Numărul de absențe"
          name="absences_count"
          rules={[
            {
              required: true,
              type: "number",
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(40% - 8px)",
          }}
        >
          <InputNumber
            min={0}
            max={999}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="absences_count_justified"
          label="Din care motivate"
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            display: "inline-block",
            width: "calc(40% - 8px)",
            margin: "0 8px",
          }}
        >
          <InputNumber
            decimalSeparator="."
            min={0}
            max={999}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Form.Item>

      <Space>
        <Button type="primary" htmlType="submit">
          Calculează
        </Button>
        <Button htmlType="reset" onClick={() => form.setFieldsValue({})}>
          Resetează
        </Button>
        <Button htmlType="button" type="link" onClick={randomizeValues}>
          Valori aleatorii
        </Button>
      </Space>
    </Form>
  );
};

export default PredictionForm;
